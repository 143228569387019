interface GradientButtonProps {
  image: string;
  smallText: string;
  bigText: string;
  link?: string;
  plain?: boolean;
  hasBorder?: boolean;
}
const GradientButton: React.FC<GradientButtonProps> = ({
  image,
  smallText,
  bigText,
  link = "/",
  plain = false,
  hasBorder = false,
}) => {
  return (
    <a
      href={link}
      target={"_blank"}
      className={`${
        plain ? "bg-white text-black" : "bg-black text-white"
      } py-1.5 rounded-lg flex items-center justify-center w-44 mb-5 ${
        hasBorder ? "border border-white" : ""
      }`}
    >
      <img alt="link" src={image} />
      <span className="flex flex-col ml-5 text-left">
        <span className="text-xs font-light">{smallText}</span>
        <span>{bigText}</span>
      </span>
    </a>
  );
};

export default GradientButton;
