import { httpCoins } from "api";
import Footer from "components/layout/Footer";
import Header from "components/layout/Header";
import { Carousel, Slide } from "components/ui/Carousel";
import { Helmet } from "react-helmet-async";
import { useQuery } from "react-query";
import { Link } from "react-router-dom";
import { publicRoutes } from "routes/routes";
import currency, { moneyFormat } from "utilities/currency";

const Home: React.FC = () => {
  const {
    data: { prices },
  } = useQuery(["coins"], () => httpCoins(), {
    initialData: {
      prices: [],
      rate: {
        buy: {
          usd: 0,
        },
        sell: {
          usd: 0,
        },
      },
    },
  });

  return (
    <>
      <Header />
      <Helmet>
        <title>Bitoshi Africa | The Easy Way to Cryptocurrencies</title>
        <meta
          name="description"
          content="Bitoshi Africa is a cryptocurrency fintech company passionate about building products that are in line with the needs of users interested in cryptocurrency in Africa and beyond."
        />
        <meta
          name="keywords"
          content="Bitoshi Africa | Cryptocurrency | Bitcoin | Digital Assets"
        />
      </Helmet>
      <div className="flex flex-col items-center justify-center w-full px-3 py-20 pt-40 text-white bg-black bg-top bg-no-repeat bg-pattern md:py-44">
        <h2 id="landing" className="mb-4 text-4xl font-bold md:text-5xl">
          The Easy Way to <br /> Cryptocurrencies
        </h2>
        <div className="font-thin text-center text-white text-opacity-90 max-w-[450px]">
          All you need to buy, sell, send and receive <br /> cryptocurrencies
        </div>

        <div className="flex flex-col items-center mt-10 mb-16 gap-y-5 md:flex-row md:gap-x-5">
          <a
            target="_blank"
            className="bg-white rounded-lg"
            href="https://apps.apple.com/us/app/bitoshi-africa/id1627285591"
          >
            <img
              src="/images/get-on-apple-store.svg"
              className="object-contain w-40"
              alt="get on apple store"
            />
          </a>
          <a
            target="_blank"
            className="bg-white rounded-lg"
            href="https://play.google.com/store/apps/details?id=com.bitoshi"
          >
            <img
              src="/images/get-on-playstore.svg"
              className="object-contain w-40"
              alt="get on playstore"
            />
          </a>
        </div>

        <img
          src="/images/landing.png"
          className="object-contain w-full md:mt-5 md:w-[500px]"
          alt="landing"
        />

        <div className="mt-40">
          <h3 className="mb-20 text-3xl font-bold md:text-4xl">
            Here’s why the cool peeps <br /> are using Bitoshi
          </h3>

          <div className="flex flex-col px-5 md:flex-row gap-y-10 md:gap-x-20 md:px-40">
            <div className="flex flex-col items-center lg:w-1/3">
              <p className="bg-[#9667ED] rounded-full w-8 h-8 flex items-center justify-center">
                <i className="fa fa-rocket"></i>
              </p>
              <h3 className="mt-5 mb-2">Easy to Setup</h3>
              <p className="font-thin text-white text-opacity-90">
                Our onboarding steps are pretty straightforward!
              </p>
            </div>

            <div className="flex flex-col items-center lg:w-1/3">
              <p className="bg-[#FF933A] rounded-full w-8 h-8 flex items-center justify-center">
                <i className="fa fa-lock"></i>
              </p>
              <h3 className="mt-5 mb-2">Safe and Secure</h3>
              <p className="font-thin text-white text-opacity-90">
                We consider the security of your money very seriously, and have
                the best Internet Security in place to protect it.
              </p>
            </div>

            <div className="flex flex-col items-center lg:w-1/3">
              <p className="bg-[#1557FF] rounded-full w-8 h-8 flex items-center justify-center">
                <i className="fa fa-chart-pie"></i>
              </p>
              <h3 className="mt-5 mb-2">Start Small</h3>
              <p className="font-thin text-white text-opacity-90">
                You don't need to spend tons of money to buy your first
                cryptocurrency; you can get started with just N500.
              </p>
            </div>
          </div>
        </div>
      </div>

      <div className="px-5 mt-20 md:mt-32">
        <h3 className="text-3xl font-bold md:text-4xl">
          Get familiar with our cool products
        </h3>

        <div className="mt-20 md:px-40">
          <div className="flex flex-col-reverse items-center justify-center px-5 gap-y-10 md:flex-row">
            <div className="text-left max-w-[600px]">
              <h3 className="text-3xl font-medium">We’ve made it simple</h3>
              <p className="mt-4 mb-8 text-lg font-light">
                Bitoshi lets you buy and sell cryptocurrency directly into other
                wallets, unlike other platforms. So you can trade without having
                to keep a cryptocurrency with a high level of volatility.
              </p>
              <Link to={publicRoutes.externalTrade}>
                <button className="px-8 py-3 font-medium text-white bg-black rounded-lg">
                  Learn more
                </button>
              </Link>
            </div>
            <img
              src="/images/we_ve-made-it-simple.png"
              className="object-contain max-w-[400px]"
              alt="made it simple"
            />
          </div>

          <div className="flex flex-col items-center justify-center mt-14 md:mt-0 gap-y-10 md:flex-row gap-x-12">
            <img
              src="/images/automate-your-investment.png"
              className="object-contain max-w-[400px]"
              alt="automate your investment"
            />
            <div className="text-left max-w-[600px]">
              <h3 className="text-3xl font-medium">
                Automate your Investments
              </h3>
              <p className="mt-4 mb-8 text-lg font-light">
                With our recurring buy feature, you can automate your
                cryptocurrency purchases. Spread them out over time. And you can
                do it all in 3 easy steps.
              </p>
              <Link to={publicRoutes.recurringTrade}>
                <button className="px-8 py-3 font-medium text-white bg-black rounded-lg">
                  Learn more
                </button>
              </Link>
            </div>
          </div>

          <div className="flex flex-col-reverse items-center justify-center gap-y-10 mt-14 md:flex-row gap-x-12">
            <div className="text-left max-w-[600px]">
              <h3 className="text-3xl font-medium">
                Send and receive crypto with just your username
              </h3>
              <p className="mt-4 mb-8 text-lg font-light">
                Send and receive crypto from your friends using only your
                username. No stress!
              </p>
              <a
                className="px-8 py-3 font-medium text-white bg-black rounded-lg"
                href={process.env.REACT_APP_ONELINK}
              >
                Get started
              </a>
            </div>
            <img
              src="/images/send-and-receive-crypto.png"
              className="object-contain max-w-[400px]"
              alt="Send and receive crypto with just your username"
            />
          </div>
        </div>
      </div>

      <div className="px-10 md:px-20 flex flex-col md:flex-row mt-20 bg-[#FFF8F5] justify-center items-center py-14 gap-y-10 gap-x-24 relative">
        <div className="">
          <img
            src="/images/how-to-get-started.png"
            className="object-contain max-w-[350px]"
            alt="Send and receive crypto with just your username"
          />
        </div>

        <div className="relative self-end mb-2 text-left">
          <img
            className="absolute left-[-10%] translate-x-1/2 md:top-28 xl:top-24 h-[53%] hidden md:block"
            src="/images/horizontal-line-2.svg"
            alt="Horizontal line"
          />
          <h3 className="text-3xl font-bold text-center md:text-left">
            How to begin on Bitoshi
          </h3>
          <div className="flex flex-col gap-y-10 mt-12 max-w-[450px]">
            <div>
              <h4 className="text-xl font-medium">Set up your account</h4>
              <p className="mt-2 font-light text-black text-opacity-80">
                After downloading the app, you create a profile, set a secure
                password, verify your email address. Then log into your account.
              </p>
            </div>
            <div>
              <h4 className="text-xl font-medium">Complete other processes</h4>
              <p className="mt-2 font-light text-black text-opacity-80">
                Choose your name, set up two-factor authentication, and add your
                personal information  and upload your government-approved
                identity card.
              </p>
            </div>
            <div>
              <h4 className="text-xl font-medium">
                Fund your account and trade
              </h4>
              <p className="mt-2 font-light text-black text-opacity-80">
                After setting up your account, you can add a debit card for
                direct buying and selling. Fund your Naira wallet and start
                trading.
              </p>
            </div>
          </div>
          <div className="mt-16 text-center md:text-left">
            <a
              className="px-8 py-3 font-medium text-white bg-black rounded-lg"
              href={process.env.REACT_APP_ONELINK}
            >
              Get started
            </a>
          </div>
        </div>
      </div>

      <div className="flex flex-col items-center justify-center py-20">
        <h3 className="text-4xl font-bold">
          Discover multiple coins on Bitoshi
        </h3>
        <p className="mt-3 font-light text-black text-opacity-70">
          Dive into a World of Possibilities as You Trade, Swap, and <br />
          Explore Top Cryptocurrencies.
        </p>

        <div className="w-full mt-5">
          {prices.length ? (
            <Carousel
              options={{
                perView: 4,
                autoplay: 5000,
                peek: 50,
                breakpoints: {
                  1100: {
                    perView: 3,
                  },
                  670: {
                    perView: 2,
                  },
                  600: {
                    perView: 1,
                  },
                },
              }}
            >
              {prices?.map((coin: any) => (
                <Slide key={coin.name}>
                  <div className="flex flex-col px-5 py-5 bg-white shadow-2xl rounded-xl drop-shadow-2xl shadow-white">
                    <img
                      className="w-8"
                      src={`/images/${coin.symbol}.png`}
                      alt={coin.name}
                    />
                    <div className="flex flex-row mt-3 mb-1 gap-x-2">
                      <span className="font-medium">{coin.name}</span>
                      <span className="font-light text-black text-opacity-70">
                        {coin.symbol?.toUpperCase()}
                      </span>
                    </div>
                    <div className="flex flex-row gap-x-2">
                      <span>
                        {currency("usd")}
                        {moneyFormat(coin.current_price)}
                      </span>
                      <span
                        className={`font-light ${
                          coin.price_change_percentage_24h < 0
                            ? "text-red-500"
                            : "text-green-500"
                        }`}
                      >
                        {coin.price_change_percentage_24h < 0 ? "" : "+"}
                        {moneyFormat(coin.price_change_percentage_24h)}%
                      </span>
                    </div>
                  </div>
                </Slide>
              ))}
            </Carousel>
          ) : null}
        </div>
      </div>

      <div>
        <img
          src="/images/enjoy-crypto-transactions.svg"
          className="object-contain w-full"
          alt="enjoy crypto transactions"
        />
      </div>

      <div className="flex flex-col items-center justify-center mt-10 md:mt-0 gap-y-10 md:flex-row gap-x-20">
        <div className="text-center md:text-left max-w-[400px]">
          <h3 className="text-4xl font-bold">Trade with just a username</h3>
          <p className="mt-4 mb-8 text-xl font-light">
            With Bitoshi, you can easily buy, sell, send, and receive
            cryptocurrencies using only a username, making trading more
            convenient and accessible than ever before.
          </p>
          <div className="hidden mt-10 md:block">
            <a
              className="px-8 py-3 font-medium text-white bg-black rounded-lg"
              href={process.env.REACT_APP_ONELINK}
            >
              Get started
            </a>
          </div>
        </div>
        <img
          src="/images/trade-with-just-username.png"
          className="object-contain w-full md:max-w-[500px]"
          alt="trade with just username"
        />
      </div>

      <div className="flex flex-col-reverse w-full md:flex-row">
        <div className="md:w-1/2 lg:w-2/5">
          <img
            src="/images/what-kids-are-saying.png"
            className="object-contain"
            alt="testimonial user"
          />
        </div>
        <div className="px-5 bg-[#FFFBF8] md:w-1/2 lg:w-3/5 py-14 md:px-20">
          <h3 className="mb-10 text-2xl font-bold md:text-3xl md:text-left">
            Here's what some cool <br /> kids are saying
          </h3>

          <div className="flex flex-col justify-between gap-y-10 md:gap-y-16">
            <div className="flex gap-x-5">
              <img
                src="/images/profile-picture-1.png"
                className="object-contain w-10"
                alt="profile"
              />
              <div>
                <img
                  src="/images/rating.png"
                  className="object-contain w-20"
                  alt="rating"
                />
                <div>
                  <p className="mt-3 text-left text-black text-opacity-80 max-w-[400px]">
                    Since I started using Bitoshi, I have had no reason to
                    search for other options to exchange my cryptocurrency. Very
                    reliable and trustworthy
                  </p>
                  <p className="mt-1 font-medium text-left">- Akin</p>
                </div>
              </div>
            </div>

            <div className="flex gap-x-5">
              <img
                src="/images/profile-picture-3.png"
                className="object-contain w-10"
                alt="profile"
              />
              <div>
                <img
                  src="/images/rating.png"
                  className="object-contain w-20"
                  alt="rating"
                />
                <div>
                  <p className="mt-3 text-left text-black text-opacity-80 max-w-[400px]">
                    Bitoshi is the best cryptocurrency exchange i have ever used
                    with unbeatable services
                  </p>
                  <p className="mt-1 font-medium text-left">- Olamilekan</p>
                </div>
              </div>
            </div>

            <div className="flex gap-x-5">
              <img
                src="/images/profile-picture.png"
                className="object-contain w-10"
                alt="profile"
              />
              <div>
                <img
                  src="/images/rating.png"
                  className="object-contain w-20"
                  alt="rating"
                />
                <div>
                  <p className="mt-3 text-left text-black text-opacity-80 max-w-[400px]">
                    Bitoshi is the best trading platform. They have high rates,
                    low fees and their Naira withdrawals are swift with no
                    delays. Try it!
                  </p>
                  <p className="mt-1 font-medium text-left">- Daniel</p>
                </div>
              </div>
            </div>

            <div className="flex gap-x-5">
              <img
                src="/images/profile-picture-2.png"
                className="object-contain w-10"
                alt="profile"
              />
              <div>
                <img
                  src="/images/rating.png"
                  className="object-contain w-20"
                  alt="rating"
                />
                <div>
                  <p className="mt-3 text-left text-black text-opacity-80 max-w-[400px]">
                    My first trade on Bitoshi assured me that I don’t need to
                    trade my crypto anywhere else. Bitoshi to the world!
                  </p>
                  <p className="mt-1 font-medium text-left">- Samuel</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="flex flex-col items-center justify-center py-20 text-white bg-black md:py-32 md:flex-row gap-x-40 gap-y-14">
        <div>
          <h3 className="text-6xl font-black md:text-8xl">$2m</h3>
          <p className="mt-2 text-lg">Transactions processed</p>
        </div>
        <div>
          <h3 className="text-6xl font-black md:text-8xl">1k+</h3>
          <p className="mt-2 text-lg">Happy users</p>
        </div>
        <div>
          <h3 className="text-6xl font-black md:text-8xl">99%</h3>
          <p className="mt-2 text-lg">Success rate</p>
        </div>
      </div>

      <div className="px-10 mt-20 md:px-20 md:mt-32">
        <h2 className="text-2xl font-bold text-center md:text-4xl">
          Available in these countries, more to come...
        </h2>
        <div className="grid items-center justify-center grid-cols-3 mt-10 gap-y-5 md:grid-cols-6 gap-x-10">
          <div>
            <img
              src="/images/nigeria.svg"
              className="object-contain"
              alt="Nigeria"
            />
            <p className="mt-2">Nigeria</p>
          </div>
          <div>
            <img
              src="/images/kenya.svg"
              className="object-contain"
              alt="Kenya"
            />
            <p className="mt-2 text-black text-opacity-50">Kenya</p>
          </div>
          <div>
            <img
              src="/images/ghana.svg"
              className="object-contain"
              alt="Ghana"
            />
            <p className="mt-2 text-black text-opacity-50">Ghana</p>
          </div>
          <div>
            <img
              src="/images/uganda.svg"
              className="object-contain"
              alt="Uganda"
            />
            <p className="mt-2 text-black text-opacity-50">Uganda</p>
          </div>
          <div>
            <img
              src="/images/rwanda.svg"
              className="object-contain"
              alt="Rwanda"
            />
            <p className="mt-2 text-black text-opacity-50">Rwanda</p>
          </div>
          <div>
            <img
              src="/images/tanzania.svg"
              className="object-contain"
              alt="Tanzania"
            />
            <p className="mt-2 text-black text-opacity-50">Tanzania</p>
          </div>
        </div>
      </div>

      <div
        className="flex flex-col items-center justify-center px-8 py-10 mx-5 text-white bg-black my-28 gap-y-5 md:flex-row md:mx-40 md:px-20 rounded-2xl gap-x-14"
        id="newsletter"
      >
        <h3 className="text-3xl font-bold text-left">
          Stay tuned via our <br className="hidden xl:block" /> Newsletter
        </h3>

        <form
          method="post"
          className="relative w-full py-1 border-none xl:bg-white xl:w-1/2 xl:bg-opacity-20 rounded-xl"
        >
          <input
            type="text"
            className="w-full h-12 px-5 py-1 bg-white border-none xl:bg-transparent placeholder:text-white placeholder:text-opacity-80 bg-opacity-20 xl:bg-opacity-100 focus:ring-0 rounded-xl xl:rounded-none"
            placeholder="Enter your email"
          />
          <button className="left-0 block px-10 py-2 mt-5 text-black bg-white rounded-lg lg:px-5 xl:left-auto xl:inline xl:translate-y-1/2 xl:absolute xl:right-4 xl:mt-0 xl:bottom-1/2">
            Subscribe
          </button>
        </form>
      </div>

      <div className="flex flex-col-reverse items-center px-5 pt-5 text-white bg-black bg-no-repeat bg-contain bg-pattern md:flex-row md:px-20 lg:px-40 lg:gap-x-20 md:gap-x-5">
        <div className="">
          <img
            alt="link"
            className="object-contain w-[450px]"
            src={"/images/just-a-step.png"}
          />
        </div>
        <div className="pt-10 md:text-left">
          <h3 className="text-3xl font-bold md:text-4xl lg:text-5xl">
            You are just a step <br /> away from the <br /> experience
          </h3>
          <p className="mt-4 font-light">
            Download the Bitoshi app <br /> today to get started.
          </p>
          <div className="flex items-center justify-between my-10 md:justify-start md:gap-x-5 gap-x-4">
            <a
              href="https://play.google.com/store/apps/details?id=com.bitoshi"
              target={"_blank"}
              className="flex items-center justify-center px-5 py-2 mb-5 text-white border border-white rounded-lg md:w-48"
            >
              <img alt="link" src={"/images/playstore.svg"} />
              <span className="flex flex-col ml-5 text-left">
                <span className="text-xs font-light">Get it on</span>
                <span>Play Store</span>
              </span>
            </a>

            <a
              href="https://apps.apple.com/us/app/bitoshi-africa/id1627285591"
              target={"_blank"}
              className="flex items-center justify-center px-5 py-2 mb-5 text-white border border-white rounded-lg md:w-48"
            >
              <img alt="link" src={"/images/appstore.png"} />
              <span className="flex flex-col ml-5 text-left">
                <span className="text-xs font-light">Get it on</span>
                <span>App Store</span>
              </span>
            </a>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default Home;
