import useGetVendor from "hooks/useGetVendor";
import { useCallback, useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { vendorRoutes } from "routes/routes";
import { SocketContext } from "socket";
import { logout } from "utilities/network";

const VendorSidebar: React.FC = () => {
  const [isOpen, setIsOpen] = useState(false);
  const navigate = useNavigate();
  const { vendor } = useGetVendor();
  const socket = useContext(SocketContext);
  const [transaction, setTransaction] = useState({
    deposit: 0,
    withdrawal: 0,
  });

  const subscriptionType = `dashboardTransactions`;
  const updateType = `updateAvailable`;

  const handleIncomingTransactions = useCallback((trns: any) => {
    setTransaction(trns);
  }, []);

  const askForTransactions = useCallback(() => {
    socket.emit(subscriptionType);
  }, [socket, subscriptionType]);

  useEffect(() => {
    if (vendor.id) {
      socket.connect();
      askForTransactions();

      socket.on(subscriptionType, handleIncomingTransactions);
      socket.on(updateType, askForTransactions);
    }

    return () => {
      socket.off(subscriptionType, handleIncomingTransactions);
      socket.off(updateType, askForTransactions);
      socket.disconnect();
    };
  }, [
    handleIncomingTransactions,
    socket,
    vendor.id,
    subscriptionType,
    askForTransactions,
    updateType,
  ]);

  return (
    <div className="w-full">
      <nav className="relative">
        {isOpen ? (
          <div
            id="nav"
            className="bg-gray-400 bg-opacity-60 h-screen overflow-y-hidden absolute z-10 shadow-lg w-full font-light"
            onClick={() => setIsOpen((p) => !p)}
          >
            <div
              onClick={(e) => e.stopPropagation()}
              className="bg-white w-9/12 h-full py-6"
            >
              <div className="flex items-center justify-between w-full">
                <img
                  className="ml-5 w-[18px] h-auto object-contain"
                  src="/images/logo-small.png"
                  alt="Bitoshi"
                  onClick={() => navigate(vendorRoutes.dashboard)}
                />
                <button
                  onClick={() => setIsOpen((p) => !p)}
                  className="mr-5"
                  id="hamburger"
                  type="button"
                >
                  <i className="fa text-lg fa-bars"></i>
                </button>
              </div>
              <ul className="w-full text-left pl-5 mt-14">
                <li className="mb-8 w-full">
                  <a
                    className="w-full block font-light text-sm text-black text-opacity-70"
                    onClick={() => navigate(vendorRoutes.dashboard)}
                  >
                    <i className="fa fa-cube mr-3"></i> Dashboard
                  </a>
                </li>

                <li className="mb-8 w-full">
                  <a
                    className="w-full block font-light text-sm text-black text-opacity-70"
                    onClick={() => navigate(vendorRoutes.profile)}
                  >
                    <i className="fa fa-user-alt mr-3"></i> Profile
                  </a>
                </li>

                <li className="mb-8 w-full flex items-center content-between pr-5">
                  <a
                    className="w-full block font-light text-sm text-black text-opacity-70"
                    onClick={() => navigate(vendorRoutes.deposits)}
                  >
                    <i className="fa fa-long-arrow-alt-down mr-4"></i> Deposits
                  </a>
                  {transaction.deposit > 0 && <Dot />}
                </li>

                <li className="mb-8 w-full flex items-center content-between pr-5">
                  <a
                    className="w-full block font-light text-sm text-black text-opacity-70"
                    onClick={() => navigate(vendorRoutes.withdrawals)}
                  >
                    <i className="fa fa-long-arrow-alt-up mr-4"></i>
                    Withdrawals
                  </a>
                  {transaction.withdrawal > 0 && <Dot />}
                </li>

                <li className="mb-5 absolute bottom-10">
                  <button
                    className="rounded-lg font-normal py-2 px-16 border border-red-500 text-red-500 text-sm"
                    onClick={logout}
                  >
                    Sign Out
                  </button>
                </li>
              </ul>
            </div>
          </div>
        ) : (
          <div className="flex items-center py-6 justify-between bg-white relative px-5">
            <img
              className="w-[18px] h-auto object-contain"
              src="/images/logo-small.png"
              alt="Bitoshi"
            />
            <button
              onClick={() => setIsOpen((p) => !p)}
              className=""
              id="hamburger"
              type="button"
            >
              <i className="fa text-lg fa-bars"></i>
            </button>
          </div>
        )}
      </nav>
    </div>
  );
};

const Dot: React.FC = () => (
  <span className="bg-red-500 inline-block w-1.5 h-1.5 rounded-full"></span>
);

export default VendorSidebar;
