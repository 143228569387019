const wallets = {
  btc: {
    name: "Bitcoin",
    short: "BTC",
  },
  eth: {
    name: "Ethereum",
    short: "ETH",
  },
  bnb: {
    name: "Binance Coin",
    short: "BNB",
  },
  trx: {
    name: "Tron",
    short: "TRX",
  },
  ltc: {
    name: "Litecoin",
    short: "LTC",
  },
  usdt: {
    name: "TetherUS",
    short: "USDT",
  },
  usdc: {
    name: "USD Coin",
    short: "USDC",
  },
};

export const cryptos = Object.keys(wallets);

export default wallets;
