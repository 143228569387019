import Glide, { Options } from "@glidejs/glide";
import "@glidejs/glide/dist/css/glide.core.css";
import { forwardRef, useEffect, useImperativeHandle, useRef } from "react";
export const sliderConfiguration: Partial<Options> = {
  type: "carousel",
  gap: 20,
  perView: 3,
};

type CarouselType = {
  children: React.ReactNode;
  options?: Partial<Options>;
};

export const Carousel = forwardRef(
  ({ children, options }: CarouselType, ref) => {
    const sliderRef = useRef<HTMLDivElement>(null);

    useImperativeHandle(ref, () => sliderRef.current);

    useEffect(() => {
      const slider = new Glide(sliderRef.current as HTMLElement, {
        ...sliderConfiguration,
        ...options,
      });
      slider.mount();

      return () => {
        slider.destroy();
      };
    }, []);

    return (
      <div className="relative px-2 glide" ref={sliderRef}>
        <div className="glide__track" data-glide-el="track">
          <ul className="glide__slides">{children}</ul>
        </div>
      </div>
    );
  }
);

export const Slide = forwardRef(
  ({ children }: { children: React.ReactNode }, ref: any) => {
    return (
      <li className="py-10 glide__slide" ref={ref}>
        {children}
      </li>
    );
  }
);
