import { ReactNode, useRef, useState } from "react";
import { NumericOnly, nextBox } from "utilities/misc";

export const CircleDot: React.FC = () => (
  <span className="bg-gray-400 bg-opacity-80 w-6 h-6 rounded-full"></span>
);

export const Whys: React.FC<{
  title: string;
  subtitle: string | ReactNode;
  start?: boolean;
  top?: ReactNode;
  startBg?: boolean;
}> = ({ title, subtitle, start = false, top, startBg = false }) => (
  <div
    className={`flex flex-col ${start ? "items-start" : "items-center"} mt-12 ${
      startBg ? "md:items-start" : ""
    }`}
  >
    {top ? top : <CircleDot />}
    <h4
      className={`font-medium text-xl my-3 ${
        start ? "text-left" : "text-center"
      } ${startBg ? "md:text-left" : ""}`}
    >
      {title}
    </h4>
    <p
      className={`text-black font-light ${
        start ? "text-left" : "text-center"
      } ${startBg ? "md:text-left" : ""}`}
    >
      {subtitle}
    </p>
  </div>
);

export const VendorLinkItem: React.FC<{
  title: string;
  onClick?: () => void;
}> = ({ title, onClick }) => (
  <div
    onClick={onClick}
    className="flex justify-between items-center border-b border-b-gray-200 pb-2 cursor-pointer mb-8"
  >
    <h4 className="font-light">{title}</h4>
    <i className="fa fa-chevron-right text-orange-300 text-sm"></i>
  </div>
);

export const Input: React.FC<{
  label?: string;
  value: string;
  onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
  type?: string;
  placeholder?: string;
  error?: string;
  disabled?: boolean;
  className?: string;
  readOnly?: boolean;
}> = ({
  label,
  value,
  onChange,
  type = "text",
  placeholder,
  error,
  disabled = false,
  className,
  readOnly = false,
}) => {
  const [isPassword, setIsPassword] = useState(true);
  const processedType = type === "password" && !isPassword ? "text" : type;
  return (
    <div className="mb-5 relative">
      {label && (
        <label className="text-gray-700 text-left mb-2 block">{label}</label>
      )}
      <input
        className={`w-full border-gray-300 rounded-lg py-2 ${className}`}
        placeholder={placeholder}
        type={processedType}
        disabled={disabled}
        value={value}
        onChange={onChange}
        readOnly={readOnly}
      />
      {type === "password" && (
        <button onClick={() => setIsPassword((p) => !p)}>
          <i
            className={`fa ${
              isPassword ? "fa-eye" : "fa-eye-slash"
            } text-orange-300 absolute right-5 bottom-3 z-20`}
          ></i>
        </button>
      )}
    </div>
  );
};

export const Select: React.FC<{
  label?: string;
  value: string;
  onChange?: (e: React.ChangeEvent<HTMLSelectElement>) => void;
  type?: string;
  placeholder?: string;
  error?: string;
  disabled?: boolean;
  className?: string;
  data?: string[];
  name?: string;
}> = ({
  label,
  value,
  onChange,
  type = "text",
  placeholder,
  error,
  disabled = false,
  className,
  data,
  name,
}) => (
  <div className="mb-5">
    {label && (
      <label className="text-gray-700 text-left mb-2 block">{label}</label>
    )}
    <select
      name={name}
      className={`w-full border-gray-300 rounded-lg py-2 ${className}`}
      placeholder={placeholder}
      disabled={disabled}
      value={value}
      onChange={onChange}
    >
      {data?.map((d) => (
        <option key={d}>{d}</option>
      ))}
    </select>
  </div>
);

export const PINInput: React.FC<{
  label?: string;
  pin: string;
  setPin: (value: string) => void;
  type?: string;
  placeholder?: string;
  error?: string;
  disabled?: boolean;
  className?: string;
  boxLength: number;
}> = ({
  label,
  pin,
  setPin,
  type = "text",
  placeholder,
  error,
  disabled = false,
  className,
  boxLength,
}) => {
  const list = Array.from(Array(boxLength).keys());
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const ref1 = list.map((x) => useRef(null));

  const handleInput = (
    text: string,
    index: number,
    refs: any,
    remove: boolean = false
  ) => {
    if (text && !NumericOnly(text)) {
      return;
    }
    text = text.replace(/[^0-9]/g, "");
    let tempPIN = pin.split("");
    tempPIN.splice(index, remove ? 1 : 0, ...text.split(""));
    let cleanPIN = tempPIN.join("").slice(0, boxLength);
    setPin(cleanPIN);
    let nextIndex = nextBox(
      cleanPIN.length,
      boxLength,
      !remove && Boolean(text)
    );
    return refs[nextIndex].current?.focus();
  };
  return (
    <div className="mb-5">
      {label && (
        <label className="text-gray-700 text-left mb-2 block">{label}</label>
      )}
      <div className="flex">
        {list.map((ar, index) => (
          <input
            key={ar}
            className={`w-full border-gray-300 first:rounded-l-lg last:rounded-r-lg py-2 ${className}`}
            placeholder={placeholder}
            type={type}
            ref={ref1[index]}
            disabled={disabled}
            value={pin.length > ar ? pin[ar] : ""}
            onChange={(e) => handleInput(e.target.value, index, ref1)}
            onKeyDown={({ nativeEvent }) => {
              return nativeEvent.key === "Backspace"
                ? handleInput("", index, ref1, true)
                : null;
            }}
          />
        ))}
      </div>
    </div>
  );
};
