import copy from "clipboard-copy";
import Footer from "components/layout/Footer";
import Header from "components/layout/Header";
import GradientButton from "components/ui/GradientButton";
import axios from "Lib/Axios";
import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { publicRoutes } from "routes/routes";
import { capitalize, showSuccess } from "utilities/misc";

const Refer: React.FC = () => {
  const navigate = useNavigate();
  const username = new URLSearchParams(useLocation().search).get("username");

  if (!username) navigate(publicRoutes.home);

  const [referralCode, setReferralCode] = useState("");

  const cp = async () => {
    await copy(referralCode?.toUpperCase());
    showSuccess("copied to clipboard");
  };

  useEffect(() => {
    axios
      .get(`${process.env.REACT_APP_SOCKET_URL}/refer?username=${username}`)
      .then(({ data }) => {
        if (data?.data?.referralCode) {
          setReferralCode(data?.data?.referralCode);
        }
      })
      .catch((e) => {
        console.log(e);
        navigate(publicRoutes.home);
      });
  }, []);

  return (
    <>
      <Header />
      <div className="pt-40 md:py-44">
        <h3 className="text-4xl font-bold">Hi there!</h3>
        <p className="px-5 mt-10 text-black md:mt-2">
          <b className="font-bold">{capitalize(username || "")}</b> uses Bitoshi
          to trade, store and transfer <br className="hidden md:inline" />{" "}
          cryptocurrency and he is inviting you to join Bitoshi
        </p>

        <div className="flex flex-col items-center justify-center md:flex-row gap-x-8 mt-14">
          <GradientButton
            image="/images/appstore.png"
            smallText="Get it on"
            bigText="App Store"
            link="https://apps.apple.com/us/app/bitoshi-africa/id1627285591"
          />
          <GradientButton
            image="/images/playstore.png"
            smallText="Get it on"
            bigText="Play Store"
            link="https://play.google.com/store/apps/details?id=com.bitoshi"
          />
        </div>

        <div className="pt-10 mt-10 md:flex md:px-20 md:items-center bg-pattern">
          <div className="md:text-left md:w-1/2 md:justify-between">
            <h3 className="mt-10 text-3xl font-bold text-white">
              {capitalize(username || "")}'s referral code
            </h3>
            <p className="my-3 text-white text-opacity-80 px-10 md:px-2 md:w-[80%] font-thin">
              You and {capitalize(username || "")} will get N500 if you sign up
              with his referral code, complete your identity verification and
              trade up to $100.
            </p>
            <div className="flex items-center justify-between px-5 py-3 mx-8 mt-8 mb-5 bg-white rounded-lg shadow-sm md:mx-0 md:mt-1">
              <h3 className="text-xl font-bold text-left">
                {referralCode?.toUpperCase()}
              </h3>
              <button
                className="px-8 py-2 text-white bg-black rounded-lg"
                onClick={cp}
              >
                Copy <i className="ml-2 fa fa-copy"></i>
              </button>
            </div>
            <p className="mt-10 text-sm text-black text-opacity-70">
              You and {capitalize(username || "")} will get N500 if you sign up
              with his referral code, complete your identity verification and
              trade up to $100.
            </p>
          </div>
          <div className="flex items-center justify-center md:mt-10 md:w-1/2">
            <img
              src="/images/refer.png"
              className="object-contain"
              alt="refer"
            />
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default Refer;
