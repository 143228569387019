import { ReactNode } from "react";

interface TransactionItemProps {
  title: string;
  value: string | number | undefined;
  isAmount?: boolean;
  isStatus?: boolean;
  status?: string;
  underline?: boolean;
  sub?: ReactNode;
}

const TransactionItem: React.FC<TransactionItemProps> = ({
  title,
  value,
  isAmount,
  isStatus,
  status,
  underline,
  sub,
}) => (
  <div className="flex justify-between mb-3 item-center">
    <h4 className="mb-1 mr-2 text-sm text-black text-opacity-50">{title}</h4>
    <div className="text-right">
      <p
        className={`text-black break-all ${
          isAmount ? "text-xl" : "text-sm text-opacity-70 font-light"
        } ${isStatus ? status : ""} ${underline ? "underline" : ""}`}
      >
        {isStatus && typeof value === "string"
          ? value?.replace("cancelled", "failed")
          : value}
      </p>
      {sub}
    </div>
  </div>
);

export default TransactionItem;
