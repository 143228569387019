import { httpFetchPaymentMerchants } from "api/admin/stats";
import AdminSidebar from "components/layout/AdminSidebar";
import Container from "components/layout/Container";
import Main from "components/layout/Main";
import { Select } from "components/misc";
import VButton from "components/ui/VButton";
import useAsync from "hooks/useAsync";
import { useEffect, useState } from "react";
import { useQuery } from "react-query";
import { showSuccess } from "utilities/misc";
import { post } from "utilities/network";

const PaymentMerchant: React.FC = () => {
  const [title, setTitle] = useState("");
  const [message, setMessage] = useState("");
  const [activeMerchants, setActiveMerchants] = useState<any>([]);

  const { refetch, data } = useQuery(
    "paymentMerchants",
    httpFetchPaymentMerchants,
    {
      initialData: {
        merchants: [],
        paymentMerchants: [],
      },
      onSuccess: (data) => {
        setActiveMerchants(data.paymentMerchants);
      },
    }
  );

  const {
    execute: updateMerchant,
    status,
    value,
  } = useAsync(
    () =>
      post(`admin/transactions/setPaymentMerchant`, {
        paymentMerchant: activeMerchants,
      }),
    false
  );

  const handleSubmit = () => {
    updateMerchant();
  };

  useEffect(() => {
    if (value?.data?.success) {
      showSuccess(value?.data?.message || "Updated successfully");
    }
  }, [value]);

  const handleChange = (e: any, index: number) => {
    const newMerchants = [...activeMerchants];
    const { value, name } = e.target;
    newMerchants[index][name] =
      name === "merchant" ? getMerchantByName(value)?.key : Number(value);
    setActiveMerchants(newMerchants);
  };

  const getMerchantByKey = (key: string) =>
    data?.merchants.find((a: any) => key == a.key);
  const getMerchantByName = (name: string) =>
    data?.merchants.find((a: any) => name == a.name);

  return (
    <Container>
      <AdminSidebar />
      <Main className="mx-5 mt-5 text-left">
        <h2 className="text-xl">Payment Merchant</h2>
        <div className="flex gap-8 mt-14">
          <div className="flex">
            <div className="flex flex-col gap-4">
              {data?.paymentMerchants?.map((a: any, index: number) => (
                <div
                  key={`${a?.merchant}-${index}`}
                  className="relative flex items-center gap-x-5"
                >
                  <div>
                    <label>Merchant {index + 1}</label>
                    <Select
                      name="merchant"
                      onChange={(e) => handleChange(e, index)}
                      data={data?.merchants.map((p: any) => p.name)}
                      value={
                        getMerchantByKey(activeMerchants[index]?.merchant)?.name
                      }
                    />
                  </div>
                  <span> {"=>"} </span>
                  <div className="flex items-center mb-5 gap-x-3">
                    <div>
                      <label className="mb-2">Min. Amount</label>
                      <input
                        type="number"
                        name="min"
                        value={activeMerchants[index]?.min}
                        onChange={(e) => handleChange(e, index)}
                        className="w-full py-2 border-gray-300 rounded-lg"
                      />
                    </div>
                    <div>
                      <label className="mb-2">Max. Amount</label>
                      <input
                        type="number"
                        name="max"
                        value={activeMerchants[index]?.max}
                        onChange={(e) => handleChange(e, index)}
                        className="w-full py-2 border-gray-300 rounded-lg"
                      />
                    </div>
                  </div>
                </div>
              ))}
              <VButton
                status={status}
                onClick={handleSubmit}
                className="w-5/12 px-20 py-3 font-light text-white bg-black rounded-lg"
              >
                Save
              </VButton>
            </div>
          </div>
        </div>
      </Main>
    </Container>
  );
};
export default PaymentMerchant;
