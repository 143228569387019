import { httpUserTransactions } from "api/admin/user";
import AdminSidebar from "components/layout/AdminSidebar";
import Container from "components/layout/Container";
import Main from "components/layout/Main";
import Filter from "components/ui/Filter";
import Pagination from "components/ui/Pagination";
import Search from "components/ui/Search";
import Table from "components/ui/Table";
import Toggle from "components/ui/Toggle";
import { Itransaction } from "components/ui/Transaction";
import { DateTime } from "luxon";
import { useEffect, useState } from "react";
import { useQuery } from "react-query";
import { useParams } from "react-router-dom";
import currency, { cryptoFormat, moneyFormat } from "utilities/currency";
import { capitalize } from "utilities/misc";
import TransactionInfo from "../transactions/TransactionInfo";

const typesTitle: any = {
  Naira: "naira",
  "Buy & Sell": "market",
  "Send & Receive": "send-receive",
  Swap: "swap",
  "Recurring Buy": "recurring",
};

const limit = 50;
const UserTransactions: React.FC = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [transaction, setTransaction] = useState<Itransaction | null>(null);
  const [value, setValue] = useState("Naira");
  const [filter, setFilter] = useState("deposit");
  const params = useParams();
  const userID = params.user as string;

  const typeValue = typesTitle[value];

  const placeNFilter = () => {
    if (typeValue === "naira") {
      return {
        filter,
        place: "",
      };
    } else if (typeValue === "market" || typeValue === "send-receive") {
      let sp = filter.split("-");
      return {
        filter: sp[0],
        place: sp[1],
      };
    } else if (typeValue === "swap") {
      return {
        filter: "swap",
        place: "",
      };
    }

    return {
      filter,
      place: "",
    };
  };

  useEffect(() => {
    setFilter(filters()[0].value);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [value]);

  const currencyType = typeValue === "naira" ? "naira" : "crypto";

  const {
    data: { transactions, total, page },
  } = useQuery(
    [
      "userTransactions",
      userID,
      currencyType,
      placeNFilter().filter,
      placeNFilter().place,
      currentPage,
    ],
    () =>
      httpUserTransactions(
        userID,
        currencyType,
        placeNFilter().filter,
        placeNFilter().place,
        limit,
        currentPage
      ),
    {
      initialData: {
        transactions: [],
      },
    }
  );

  const handleClick = (tr: Itransaction) => {
    if (transaction?.id === tr.id) {
      setTransaction(null);
      setIsOpen(false);
    } else {
      setTransaction(tr);
      setIsOpen(true);
    }
  };

  const filters = () => {
    if (typeValue === "naira") {
      return [
        {
          label: "Deposit Naira",
          value: "deposit",
        },
        {
          label: "Send Money",
          value: "withdrawal",
        },
        {
          label: "Earned Naira",
          value: "earning",
        },
        {
          label: "Refund",
          value: "refund",
        },
        {
          label: "Pay Bills",
          value: "bill",
        },
      ];
    } else if (typeValue === "market") {
      return [
        {
          label: "Buy to external wallet",
          value: "buy-external",
        },
        {
          label: "Buy to Bitoshi wallet",
          value: "buy-bitoshi",
        },
        {
          label: "Sell from external wallet",
          value: "sell-external",
        },
        {
          label: "Sell from Bitoshi wallet",
          value: "sell-bitoshi",
        },
      ];
    } else if (typeValue === "send-receive") {
      return [
        {
          label: "Send: External transfer",
          value: "send-external",
        },
        {
          label: "Send: Internal transfer",
          value: "send-bitoshi",
        },
        {
          label: "Receive: External transfer",
          value: "receive-external",
        },
        {
          label: "Receive: Internal transfer",
          value: "receive-bitoshi",
        },
        {
          label: "Send Money",
          value: "withdrawal",
        },
        {
          label: "Refund",
          value: "refund",
        },
        {
          label: "Pay Bills",
          value: "bill",
        },
      ];
    } else if (typeValue === "recurring") {
      return [
        {
          label: "Active plans",
          value: "active",
        },
        {
          label: "Paused plans",
          value: "paused",
        },
        {
          label: "Cancelled plans",
          value: "stopped",
        },
        {
          label: "Purchases",
          value: "purchases",
        },
      ];
    }
    return [
      {
        label: "Swap",
        value: "swap",
      },
    ];
  };

  const transactionAmount = (tr: Itransaction) =>
    tr?.currencyType === "naira"
      ? `${currency("ngn")} ${moneyFormat(tr?.amount)}`
      : `${cryptoFormat(tr?.amount || 0)} ${tr?.crypto?.toUpperCase() || ""}`;

  const firstTitle = () => {
    if (typeValue === "send-receive" || typeValue === "swap") {
      return "Type";
    } else {
      return "User";
    }
  };

  const firstItem = (transaction: Itransaction) => {
    if (placeNFilter().filter === "send") {
      return `Sent ${transaction.crypto?.toUpperCase()}`;
    } else if (placeNFilter().filter === "receive") {
      return `Received ${transaction.crypto?.toUpperCase()}`;
    } else if (placeNFilter().filter === "swap") {
      return `${transaction.fromCrypto?.toUpperCase()} - ${transaction.crypto?.toUpperCase()}`;
    }
    return `@${transaction?.user?.userName}`;
  };

  const secondTitle = () => {
    if (typeValue === "naira") {
      return capitalize(filter) + " amount";
    } else if (typeValue === "market") {
      return "Amount";
    } else if (typeValue === "send-receive" || typeValue === "swap") {
      return "User";
    } else {
      return "Asset";
    }
  };

  const secondItem = (transaction: Itransaction) => {
    if (typeValue === "send-receive" || typeValue === "swap") {
      return `@${transaction?.user?.userName}`;
    } else if (typeValue === "recurring") {
      return transaction.crypto?.toUpperCase();
    }
    return `${transactionAmount(transaction)}`;
  };

  const thirdTitle = () => {
    if (typeValue === "recurring" && filter !== "purchases") return "Frequency";
    else if (typeValue === "recurring" && filter === "purchases")
      return "Amount";
    return "Date & Time";
  };

  const thirdItem = (transaction: Itransaction) => {
    if (typeValue === "recurring") {
      if (filter === "purchases") {
        return `${currency("ngn")} ${transaction.amount}`;
      }
      return capitalize(transaction.frequency);
    }
    return `${DateTime.fromISO(transaction.timestamp).toFormat(
      "DD"
    )} ${DateTime.fromISO(transaction.timestamp).toFormat("t")}`;
  };

  return (
    <Container>
      <AdminSidebar />
      <Main className="mx-5 mt-5 text-left">
        <div className="flex items-center justify-between w-full">
          <h3 className="text-2xl">@ transactions</h3>
          <div className="w-3/6">
            <Search type="user" />
          </div>
        </div>

        <div className="my-10">
          <Toggle
            data={[
              "Naira",
              "Buy & Sell",
              "Send & Receive",
              "Swap",
              "Recurring Buy",
            ]}
            value={value}
            setValue={setValue}
          />

          {typeValue !== "swap" && (
            <div className="mt-10">
              <span className="text-sm">Filter by:</span>
              <Filter data={filters()} setValue={setFilter} value={filter} />
            </div>
          )}
        </div>

        <div className="flex gap-8 mt-10">
          <div className="w-7/12">
            <Table
              header={[firstTitle(), secondTitle(), thirdTitle()]}
              data={[
                ...(transactions || []).map((transaction: Itransaction) => [
                  firstItem(transaction),
                  secondItem(transaction),
                  thirdItem(transaction),
                  () => handleClick(transaction),
                ]),
              ]}
            />
            <Pagination
              setCurrentPage={setCurrentPage}
              currentPage={currentPage}
              limit={limit}
              page={page}
              total={total}
            />
          </div>

          {isOpen && <TransactionInfo transaction={transaction} />}
        </div>
      </Main>
    </Container>
  );
};
export default UserTransactions;
