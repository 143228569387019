import { httpGetTransaction } from "api/vendor/transactions";
import Container from "components/layout/Container";
import Main from "components/layout/Main";
import VendorNav from "components/layout/VendorNav";
import VendorSidebar from "components/layout/VendorSidebar";
import VButton from "components/ui/VButton";
import useAsync from "hooks/useAsync";
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { vendorRoutes } from "routes/routes";
import currency, { moneyFormat } from "utilities/currency";
import { showSuccess } from "utilities/misc";
import { post } from "utilities/network";

const DepositItem: React.FC = () => {
  const params = useParams();
  const navigate = useNavigate();
  const [transaction, setTransaction] = useState<any>({});

  const {
    execute: confirmTransaction,
    status,
    value,
  } = useAsync(
    () => post(`vendor/transactions/confirm/${params?.item}`, {}),
    false
  );

  const {
    execute: cancelTransaction,
    status: status2,
    value: value2,
  } = useAsync(
    () => post(`vendor/transactions/cancel/${params?.item}`, {}),
    false
  );

  const handleSubmit = () => {
    confirmTransaction();
  };
  const handleSubmit2 = () => {
    cancelTransaction();
  };

  useEffect(() => {
    if (value?.data?.success) {
      showSuccess("Transaction completed");
      navigate(vendorRoutes.dashboard);
    }
  }, [value, navigate]);

  useEffect(() => {
    if (value2?.data?.success) {
      showSuccess("Transaction cancelled");
      navigate(vendorRoutes.dashboard);
    }
  }, [value2, navigate]);

  useEffect(() => {
    if (!params.item) {
      return navigate(vendorRoutes.deposits);
    }
  }, [params?.item, navigate]);

  const getTransaction = async () => {
    let tr = await httpGetTransaction(params?.item);
    if (!tr.transaction) {
      return navigate(vendorRoutes.deposits);
    }
    setTransaction(tr.transaction);
  };

  useEffect(() => {
    getTransaction();
  }, []);

  // const {
  //   data: { transaction },
  // } = useQuery(
  //   ["depositTransaction", params?.item],
  //   () => httpGetTransaction(params?.item),
  //   {
  //     initialData: { transaction: {} },
  //     onSuccess: (data) => {
  //       if (!data.transaction) {
  //         return navigate(vendorRoutes.deposits);
  //       }
  //     },
  //   }
  // );

  const bank =
    transaction?.address && transaction?.address !== "sell.bitoshi.africa"
      ? JSON.parse(transaction.address)
      : {};

  return (
    <Container isVendor>
      <VendorSidebar />

      <Main isVendor>
        <VendorNav
          title={`${currency("ngn")}${moneyFormat(
            transaction?.place === "external"
              ? Number(transaction.amount)
              : Number(transaction.amount) - Number(transaction.fee)
          )}`}
        />

        <div className="mt-10">
          <div className="border border-black rounded-lg mt-5 py-5 px-5">
            {transaction?.place === "external" ? (
              <span className="text-xs bg-orange-300 rounded px-1">
                sell.bitoshi.africa
              </span>
            ) : (
              ""
            )}
            <h4 className="text-left mb-5">Account Details</h4>

            <div className="flex justify-between mb-5">
              <h5 className="font-light text-sm text-black text-opacity-60">
                Bank Name
              </h5>
              <p className="text-sm">{bank.bankName}</p>
            </div>
            <div className="flex justify-between mb-5">
              <h5 className="text-sm text-black text-opacity-60 font-light">
                Account Number
              </h5>
              <p className="text-sm">{bank.accountNumber}</p>
            </div>
            <div className="flex justify-between mb-5">
              <h5 className="text-sm text-black text-opacity-60 font-light">
                Account Name
              </h5>
              <p className="">{bank.accountName}</p>
            </div>
          </div>
          <div className="mt-10">
            <VButton
              onClick={handleSubmit}
              status={status}
              className="bg-orange-300 py-4 text-white w-full"
            >
              Payment Completed
            </VButton>

            {transaction?.status === "pending" && (
              <VButton
                onClick={handleSubmit2}
                status={status2}
                className="border border-orange-200 py-4 text-orange-300 mt-4 bg-transparent w-full"
              >
                Cancel
              </VButton>
            )}
          </div>
        </div>
      </Main>
    </Container>
  );
};

export default DepositItem;
