import Spinner from "./Spinner";

interface TableProps {
  header: string[];
  data: any[][];
}
interface MetricsTableProps {
  header: string[];
  sidebar: string[];
  title: string;
  data: any[][];
  isLoading?: boolean;
}

const Table: React.FC<TableProps> = ({ header, data }) => (
  <table className="w-full overflow-hidden border-collapse rounded-lg">
    <thead>
      <tr className="text-white bg-black">
        {header.map((h, index) => (
          <th key={`${h}-${index}`} className="px-10 py-3 text-left">
            <span className="text-sm font-medium">{h}</span>
          </th>
        ))}
      </tr>
    </thead>
    <tbody className="">
      {data.map((d, index) => (
        <tr key={String(index)} className="even:bg-orange-50 odd:bg-gray-50">
          {d.slice(0, d.length - 1).map((dd, ndex) => (
            <td
              onClick={d[d.length - 1]}
              key={String(`${dd}-${ndex}`)}
              className="px-8 py-5 text-left cursor-pointer"
            >
              <span className="text-sm font-light">{dd}</span>
            </td>
          ))}
        </tr>
      ))}
    </tbody>
  </table>
);

export const MetricsTable: React.FC<MetricsTableProps> = ({
  header,
  data,
  sidebar,
  title,
  isLoading,
}) => (
  <table className="w-full overflow-hidden border-collapse rounded-lg">
    <thead>
      <tr className="text-white bg-black">
        <th className="px-10 py-3 text-left">
          <span className="text-sm font-medium">{title}</span>
        </th>
        {header?.map((h) => (
          <th key={h} className="px-10 py-3 text-left">
            <span className="text-sm font-medium">{h}</span>
          </th>
        ))}
      </tr>
    </thead>
    <tbody className="">
      {isLoading ? (
        <tr className="">
          <td className="opacity-70" colSpan={4} rowSpan={3}>
            <div className="flex items-center justify-center w-full h-64">
              <Spinner className="text-5xl" />
            </div>
          </td>
        </tr>
      ) : (
        data?.map((d, index) => (
          <tr key={String(index)} className="even:bg-orange-50 odd:bg-gray-50">
            <th className="px-10 py-3 text-left">
              <span className="text-sm font-medium">{sidebar?.[index]}</span>
            </th>
            {d?.map((dd, ndex) => (
              <td
                // onClick={d?.[d.length - 1] || (() => {})}
                key={String(`${dd}-${ndex}`)}
                className="px-8 py-5 text-left cursor-pointer"
              >
                <span className="text-sm font-light">{dd}</span>
              </td>
            ))}
          </tr>
        ))
      )}
    </tbody>
  </table>
);
export default Table;
